@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;1,300&display=swap');
@font-face {
  font-family: 'Chopsin';
  src: url('./theme/FontsChopsin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Chopsin', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Customize the scrollbar */
::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
}

/* Customize the scrollbar track */
::-webkit-scrollbar-track {
  background: #20293a; /* Set the background color of the track */
}

/* Customize the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #001519; /* Set the background color of the thumb */
  border-radius: 6px; /* Add rounded corners to the thumb */
}

/* Customize the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: #787c96; /* Change the background color of the thumb when hovering */
}